<template>
    <div v-if="actions.length" class="action-buttons column q-gutter-y-xs">
        <re-button
            v-for="(a, i) in actions"
            :key="i"
            size="sm"
            :dense="$q.screen.gt.sm"
            class="full-width"
            :color="a.color"
            :icon="a.icon"
            :target="a.target"
            :disable="!!a.disable"
            :href="a.href ? a.href : undefined"
            :to="a.to ? a.to : undefined"
            :loading="a.loading"
            :aria-label="a.label"
            :label="$t(`monolith.action-buttons.${a.label}`)"
            @click="a.click ? a.click() : undefined"
        />
    </div>
    <re-dialog v-model="isOwnershipDialogOpen" has-cancel @confirm="request.updateOwner()">
        {{ $t('monolith.dashboard.sub_modal.content') }}
    </re-dialog>
</template>

<script>
import utils from '@/shared/plugins/utils'
import axios from '@/shared/plugins/axios.js'
import useRequest from '@/composables/request.js'

export default {
    name: 'ActionButtons',
    inject: ['curUser'],
    props: {
        row: {
            type: Object,
            required: true,
        },
    },
    emits: ['update'],
    data() {
        const request = useRequest(this.row.valuation_request_ref, { startDisabled: true })
        return {
            request,
            now: Date.now(),
            disabledDownloads: false,
            ersEditLoading: false,
            disabledGeneration: false,
            isOwnershipDialogOpen: false,
        }
    },
    computed: {
        hasRole() {
            return this.curUser.hasRole
        },
        isAssignedValuer() {
            return this.row.valuer?.is_self
        },
        reportUrl() {
            if (this.row.valuation_type === 'oepc')
                return (
                    utils.urlJoin(import.meta.env.VITE_APP_API_URL || '', [
                        this.$config.VALUATION_API_URL,
                        'document',
                        this.row.report_ref,
                    ]) + '?view=true'
                )

            let lang
            if (this.row.valuation_type == 'ovm') {
                lang = this.$route.params.lang
            } else {
                lang = this.row.last_submission_lang || this.$route.params.lang
            }
            const baseString = this.row.deployment ? `//${this.row.deployment}.rock.estate` : ''
            return (
                baseString +
                `${utils.urlJoin(this.$config.VALUATION_API_URL, '/report')}?` +
                `valuation_request_ref=${this.row.displayRequestRef}` +
                `&lang=${lang}`
            )
        },
        floodReportUrl() {
            const lang = this.row.last_submission_lang || this.$route.params.lang
            const baseString = this.row.deployment ? `//${this.row.deployment}.rock.estate` : ''
            let url = utils.urlJoin(this.$config.VALUATION_API_URL, [
                'v2',
                'request',
                this.row.displayRequestRef,
                'report',
            ])
            return `${baseString}${url}?purpose=flood_report&lang=${lang}`
        },
        valuationActions() {
            return [
                // {
                //     label: 'info',
                //     icon: 'help-circle',
                //     show:  this.row.status === 'needs-review',
                //     click:  () => this.view_details(this.row),
                // },
                {
                    label: 'edit',
                    color: 'warning',
                    icon: 'mdi-pencil',
                    target: this.row.deployment ? '_blank' : null,
                    show: this.row.owner.is_self && this.row.status === 'draft',
                    href: this.row.deployment
                        ? `https://${this.row.deployment}.rock.estate/valuation/${this.$route.params.lang}/request/extra-info?valuation_request_ref=${this.row.displayRequestRef}`
                        : null,
                    to: !this.row.deployment
                        ? {
                              name: 'valuation.edit',
                              query: {
                                  valuation_request_ref: this.row.valuation_request_ref,
                              },
                          }
                        : null,
                },
                {
                    label: 'edit',
                    color: 'warning',
                    icon: 'mdi-pencil',
                    show:
                        this.row.owner.is_self &&
                        this.row.valuation_type === 'dvm' &&
                        this.row.status === 'submitted' &&
                        this.now - this.row.modified_at < 60 * 60 * 1000,
                    click: () => this.unsubmit(this.row),
                },
                {
                    label: 'cancel',
                    color: 'negative',
                    icon: 'mdi-cancel',
                    show:
                        (!this.row.deployment && this.hasRole('valuer', 'dvm')) ||
                        (this.row.owner.is_self &&
                            (this.row.status === 'draft' ||
                                (this.row.status === 'submitted' &&
                                    this.now - this.row.modified_at < 60 * 60 * 1000))),
                    click: () => this.cancelSubmission(),
                },
                {
                    label: 'unvalue',
                    color: 'negative',
                    icon: 'mdi-undo',
                    show:
                        this.hasRole('dispatcher', 'ovm') &&
                        this.row.valuation_type === 'ovm' &&
                        this.row.status === 'valued',
                    click: () => this.unvalue(),
                },
                {
                    label:
                        this.row.ovm_status === 'confirmation-pending' && this.isAssignedValuer
                            ? 'confirm-appointment'
                            : 'appointment',
                    color: 'primary',
                    icon:
                        this.row.ovm_status === 'confirmation-pending' && this.isAssignedValuer
                            ? 'mdi-pencil'
                            : 'mdi-eye',
                    show:
                        (this.row.owner.is_self ||
                            (this.isAssignedValuer && this.row.ovm_status != 'payment-pending') ||
                            this.row.borrower?.is_self ||
                            this.hasRole('dispatcher', this.row.valuation_type)) &&
                        ['ovm', 'oepc'].includes(this.row.valuation_type) &&
                        [
                            'confirmation-pending',
                            'valuation-pending',
                            'appointment-pending',
                            'payment-pending',
                            'borrower-details',
                        ].includes(this.row.ovm_status),
                    to:
                        this.row.borrower?.is_self && ['submitted', 'valued'].includes(this.row.status)
                            ? {
                                  name: 'valuation.ovm.borrower',
                                  query: {
                                      step: this.row.ovm_status,
                                      valuation_request_ref: this.row.valuation_request_ref,
                                  },
                              }
                            : {
                                  name: 'valuation.ovm.summary',
                                  query: {
                                      valuation_request_ref: this.row.valuation_request_ref,
                                  },
                              },
                },
                {
                    label: 'view_request',
                    icon: 'mdi-eye',
                    show:
                        (this.row.owner.is_self || this.hasRole('reporter')) &&
                        !this.hasRole('valuer') &&
                        ['submitted', 'needs-review', 'valued', 'exotic'].includes(this.row.status) &&
                        this.row.ovm_status !== 'borrower-registration-pending',
                    to: {
                        name: 'valuation.request',
                        params: {
                            action: this.row.valuation_type === 'ovm' ? 'ovm' : 'extra-info',
                        },
                        query: {
                            valuation_request_ref: this.row.valuation_request_ref,
                        },
                    },
                },
                {
                    label: 'edit_request',
                    icon: 'mdi-pencil',
                    show:
                        (this.row.owner.is_self || this.hasRole('reporter')) &&
                        !this.hasRole('valuer') &&
                        ['submitted', 'needs-review', 'valued', 'exotic'].includes(this.row.status) &&
                        this.row.ovm_status === 'borrower-registration-pending',
                    to: {
                        name: 'valuation.request',
                        params: {
                            action: 'ovm',
                        },
                        query: {
                            valuation_request_ref: this.row.valuation_request_ref,
                        },
                    },
                    color: 'primary',
                },
                // We'll keep the old valuer tool around until the new one is feature complete
                {
                    label: 'dvm',
                    color: 'primary',
                    icon: 'mdi-eye',
                    target: this.row.deployment ? '_blank' : null,
                    show:
                        this.hasRole('valuer', 'dvm') &&
                        this.row.valuation_type === 'dvm' &&
                        // this.isAssignedValuer &&
                        ['submitted', 'needs-review', 'valued', 'awaiting-onsite'].includes(this.row.status),
                    href: this.row.deployment
                        ? `https://${this.row.deployment}.rock.estate/valuation/${this.$route.params.lang}/dvm?valuation_request_ref=${this.row.displayRequestRef}`
                        : null,
                    to: !this.row.deployment
                        ? {
                              name: 'valuation.dvm',
                              query: {
                                  valuation_request_ref: this.row.valuation_request_ref,
                              },
                          }
                        : null,
                },
                {
                    label: 'ovm',
                    color: 'primary',
                    icon: 'mdi-pencil',
                    target: this.row.deployment ? '_blank' : null,
                    show:
                        this.hasRole('valuer', 'ovm') &&
                        this.row.valuation_type === 'ovm' &&
                        this.isAssignedValuer &&
                        ['submitted', 'valued'].includes(this.row.status) &&
                        ['valuation-pending'].includes(this.row.ovm_status),
                    href: this.row.deployment
                        ? `https://${this.row.deployment}.rock.estate/valuation/${this.$route.params.lang}/valuer-app?valuation_request_ref=${this.row.displayRequestRef}`
                        : null,
                    to: !this.row.deployment
                        ? {
                              name: `valuation.valuer-app.${this.$q.screen.lt.md ? 'overview' : 'general'}`,
                              query: {
                                  valuation_request_ref: this.row.valuation_request_ref,
                              },
                          }
                        : null,
                },
                // {
                //     label: 'automatic_valuation',
                //     color: 'positive',
                //     icon: 'mdi-eye',
                //     show:  this.row.status === 'submitted' || this.row.status === 'valued',
                //     to:  ({
                //         name: 'valuation.avm',
                //         params: { step: 1 },
                //         query: {
                //             valuation_request_ref: this.row.valuation_request_ref,
                //         },
                //     }),
                // },
                {
                    label: 'web_report',
                    icon: 'mdi-file-document-outline',
                    color: 'positive',
                    show:
                        (this.row.owner.is_self ||
                            this.hasRole(['reporter', 'dispatcher'], this.row.valuation_type) ||
                            this.row.borrower?.is_self ||
                            this.isAssignedValuer) &&
                        this.showReport(
                            this.row.valuation_type,
                            this.row.status,
                            'web',
                            this.isAssignedValuer
                        ),
                    to: {
                        name: 'valuation.report',
                        query: {
                            valuation_request_ref: this.row.valuation_request_ref,
                            static: 'true',
                        },
                    },
                },
                {
                    label: this.disabledDownloads ? 'downloading' : 'pdf_report',
                    color: 'positive',
                    icon: 'mdi-download',
                    show:
                        (this.row.owner.is_self ||
                            this.hasRole(['reporter', 'dispatcher'], this.row.valuation_type) ||
                            this.row.borrower?.is_self ||
                            this.isAssignedValuer) &&
                        this.showReport(
                            this.row.valuation_type,
                            this.row.status,
                            'pdf',
                            this.isAssignedValuer
                        ),
                    href: this.reportUrl,
                    click: () => {
                        this.disabledDownloads = true
                    },
                    disable: this.disabledDownloads,
                },
                {
                    label: this.row.report_ref ? 'regenerate_pdf_report' : 'generate_pdf_report',
                    color: 'primary',
                    icon: 'mdi-file-document-outline',
                    show:
                        !this.row.deployment && // This is not going to work from the dispatcher dashboard
                        this.hasRole('dispatcher', this.row.valuation_type) &&
                        this.row.status == 'valued',
                    click: () => {
                        this.disabledGeneration = true
                        axios
                            .post(utils.urlJoin(this.$config.VALUATION_API_URL, ['report']), null, {
                                params: {
                                    valuation_request_ref: this.row.valuation_request_ref,
                                },
                            })
                            .then((response) =>
                                // TODO: report_ref is a computed property, so this shouldn't be a patch but instead it should
                                // ideally invalidate the state of the request and refetch it, or more hacky, just set report_ref accordingly in the frontend
                                // Maybe this currently works but the PATCH can't be succeeding.
                                this.request.patch({
                                    report_ref: response.data[0].document_ref,
                                })
                            )
                            .finally(() => {
                                this.disabledGeneration = false
                                this.disabledDownloads = false
                            })
                    },
                    disable: this.disabledGeneration,
                },
                {
                    label: this.disabledDownloads ? 'downloading' : 'pdf_flood_report',
                    color: 'positive',
                    icon: 'mdi-download',
                    show:
                        (this.row.owner.is_self ||
                            this.hasRole(['reporter', 'dispatcher'], this.row.valuation_type) ||
                            this.row.borrower?.is_self ||
                            this.isAssignedValuer) &&
                        this.showFloodReport(this.row, 'pdf'),
                    href: this.floodReportUrl,
                    click: () => {
                        this.disabledDownloads = true
                    },
                    disable: this.disabledDownloads,
                },
                {
                    label: this.row.flood_report_ref
                        ? 'regenerate_pdf_flood_report'
                        : 'generate_pdf_flood_report',
                    color: 'primary',
                    icon: 'mdi-file-document-outline',
                    show:
                        !this.row.deployment && // This is not going to work from the dispatcher dashboard
                        this.hasRole('dispatcher', this.row.valuation_type),
                    click: () => {
                        this.disabledGeneration = true
                        axios
                            .post(
                                utils.urlJoin(this.$config.VALUATION_API_URL, [
                                    'v2',
                                    'request',
                                    this.row.valuation_request_ref,
                                    'report',
                                ]),
                                null,
                                {
                                    params: {
                                        purpose: 'flood_report',
                                        lang: this.$route.params.lang,
                                    },
                                }
                            )
                            .then((response) =>
                                // TODO: report_ref is a computed property, so this shouldn't be a patch but instead it should
                                // ideally invalidate the state of the request and refetch it, or more hacky, just set report_ref accordingly in the frontend
                                // Maybe this currently works but the PATCH can't be succeeding.
                                this.request.patch({
                                    flood_report_ref: response.data[0].document_ref,
                                })
                            )
                            .finally(() => {
                                this.disabledGeneration = false
                                this.disabledDownloads = false
                            })
                    },
                    disable: this.disabledGeneration,
                },
                {
                    label: 'web_flood_report',
                    icon: 'mdi-file-document-outline',
                    color: 'positive',
                    show:
                        (this.row.owner.is_self ||
                            this.hasRole(['reporter', 'dispatcher'], this.row.valuation_type) ||
                            this.row.borrower?.is_self ||
                            this.isAssignedValuer) &&
                        this.showFloodReport(this.row, 'web'),
                    to: {
                        name: 'valuation.flood-report',
                        query: {
                            valuation_request_ref: this.row.valuation_request_ref,
                            static: 'true',
                        },
                    },
                },
                {
                    label: 'take_ownership',
                    color: 'warning',
                    icon: 'mdi-account-convert',
                    show: this.showOwnershipButton,
                    loading: this.request.isBusy,
                    click: () => (this.isOwnershipDialogOpen = true),
                },
            ]
        },
        ersActions() {
            return [
                {
                    label: 'view_request',
                    color: 'primary',
                    show: this.row.status === 'submitted',
                    to: {
                        name: 'ers.processing',
                        query: { valuation_request_ref: this.row.valuation_request_ref },
                    },
                },
                {
                    label: 'edit',
                    color: 'warning',
                    icon: 'mdi-pencil',
                    loading: this.ersEditLoading,
                    show:
                        (this.row.status === 'draft' || this.row.status === 'failed') &&
                        (this.row.owner.is_self || this.row.borrower?.is_self),
                    click: () => this.ers_edit_draft(this.row.valuation_request_ref, this.row.status),
                },
                {
                    label: 'take_ownership',
                    color: 'warning',
                    icon: 'mdi-account-convert',
                    show: this.showOwnershipButton,
                    loading: this.request.isBusy,
                    click: () => this.request.updateOwner(),
                },
                {
                    label: 'cancel',
                    color: 'negative',
                    icon: 'mdi-cancel',
                    show: this.row.owner.is_self && this.row.first_valued_at === null,
                    click: () => this.cancelSubmission(),
                },
                {
                    label: 'web_ers_report',
                    color: 'positive',
                    icon: 'mdi-file-document-outline',
                    show:
                        (this.row.status === 'valued') &
                        (this.row.owner.is_self ||
                            this.hasRole(['reporter', 'dispatcher'], this.row.valuation_type) ||
                            this.row.borrower?.is_self),
                    to: {
                        name: 'ers.result',
                        query: { valuation_request_ref: this.row.valuation_request_ref },
                    },
                },
            ]
        },
        oepcActions() {
            return [
                {
                    label: 'appointment',
                    color: 'primary',
                    show: this.row.status === 'submitted' && this.row.borrower?.is_self,
                    to: {
                        name: 'oepc.request',
                        query: { valuation_request_ref: this.row.valuation_request_ref },
                    },
                },
                {
                    label:
                        this.row.ovm_status === 'confirmation-pending' && this.isAssignedValuer
                            ? 'confirm-appointment'
                            : 'appointment',
                    color: 'primary',
                    icon:
                        this.row.ovm_status === 'confirmation-pending' && this.isAssignedValuer
                            ? 'mdi-pencil'
                            : 'mdi-eye',
                    show: this.isAssignedValuer || this.hasRole('dispatcher', this.row.valuation_type),
                    to: {
                        name: 'valuation.ovm.summary',
                        query: {
                            valuation_request_ref: this.row.valuation_request_ref,
                        },
                    },
                },
                {
                    label: 'cancel',
                    color: 'negative',
                    icon: 'mdi-cancel',
                    show:
                        (!this.row.deployment && this.hasRole('valuer', 'dvm')) ||
                        (this.row.owner.is_self &&
                            (this.row.status === 'draft' ||
                                (this.row.status === 'submitted' &&
                                    this.now - this.row.modified_at < 60 * 60 * 1000))),
                    click: () => this.cancelSubmission(),
                },
                {
                    label: 'edit',
                    color: 'warning',
                    icon: 'mdi-pencil',
                    show:
                        (this.row.status === 'draft' || this.row.status === 'failed') &&
                        (this.row.owner.is_self || this.row.borrower?.is_self),
                    to: {
                        name: 'oepc.request',
                        query: { valuation_request_ref: this.row.valuation_request_ref },
                    },
                },
                {
                    label: 'epc_report',
                    color: 'primary',
                    show:
                        this.showReport(
                            this.row.valuation_type,
                            this.row.status,
                            'pdf',
                            this.isAssignedValuer
                        ) && this.row.report_ref,
                    href: this.reportUrl,
                },
            ]
        },
        actions() {
            if (this.row.valuation_type === 'ers') return this.ersActions.filter((a) => a.show)
            else if (this.row.valuation_type === 'oepc') return this.oepcActions.filter((a) => a.show)
            else return this.valuationActions.filter((a) => a.show)
        },
        showOwnershipButton() {
            return (
                this.$config.SUBSTITUTE_FEATURE_ENABLED &&
                !this.row.owner.is_self &&
                this.hasRole('substitute', this.row.valuation_type)
            )
        },
    },
    methods: {
        unsubmit() {
            axios
                .put(utils.val_urls(this.$config).request_ref_status(this.row.valuation_request_ref), null, {
                    params: { action: 'unsubmit' },
                })
                .then(() => this.edit_draft())
        },
        edit_draft() {
            this.$router.push({
                name: 'valuation.request',
                params: { action: 'extra-info' },
                query: {
                    valuation_request_ref: this.row.valuation_request_ref,
                },
            })
        },
        async ers_edit_draft(valuation_request_ref, status) {
            console.log(valuation_request_ref)
            this.ersEditLoading = true
            // TODO: 1. move redirect logic elsewhere 2. use composable to load request
            const res = await axios.get(
                utils.urlJoin(this.$config.VALUATION_API_URL, ['request', valuation_request_ref])
            )
            if (status === 'draft') {
                let lastVisitedPage = res.data.borrower?.is_self ? 'ers.address' : 'ers.customer_ref'
                // TODO: ensure borrower is not undefined, and clarify in which case this is helpful
                // const userIsTheOneCompletingTheFlow = !res.data.borrower || res.data.borrower.is_self
                // if (res.data.other_data?.last_page_info?.name && userIsTheOneCompletingTheFlow) {
                if (res.data.other_data?.last_page_info?.name) {
                    lastVisitedPage = res.data.other_data?.last_page_info.name
                }
                this.$router.push({
                    name: lastVisitedPage,
                    query: { valuation_request_ref },
                })
            } else if (status === 'failed') {
                this.$router.push({
                    name: 'ers.submit_summary',
                    params: { valuation_request_ref },
                })
            }
            this.ersEditLoading = false
        },
        cancelSubmission() {
            if (!confirm(this.$t('ers.dashboard.cancel_submission_message'))) return
            this.request.updateStatus('cancel')
        },
        unvalue() {
            if (!confirm(this.$t('ers.dashboard.unvalue_message_message'))) return
            this.request.updateStatus('unsubmit-valuation')
        },
        showFloodReport(row, variant) {
            return (
                // Always show the flood report is there is one generated
                (row.flood_report_ref && this.$config.REPORT_VISIBLE.includes(`flood_${variant}`)) ||
                // Dispatchers can always see the web flood report
                (this.hasRole('dispatcher', row.request_type) && variant === 'web')
            )
        },
        showReport(valuationType, status, variant, isAssignedValuer) {
            return (
                // Normally, users can only view the report after the valuation is performed, variant determined by env var.
                ('valued' == status && this.$config.REPORT_VISIBLE.includes(`${valuationType}_${variant}`)) ||
                // Assigned valuer can always view the web report before the valuation is performed
                ('submitted' == status && isAssignedValuer && variant === 'web') ||
                // Dispatchers can always see the web report before and after the valuation is performed
                (['submitted', 'valued'].includes(status) &&
                    this.hasRole('dispatcher', valuationType) &&
                    variant === 'web') ||
                // avms are not valued, they are simply submitted, so we can always view the report
                ('submitted' === status &&
                    valuationType === 'avm' &&
                    this.$config.REPORT_VISIBLE.includes(`${valuationType}_${variant}`))
            )
        },
        // TODO: is this still needed? (was on click.native)
        // clickActionBtn() {
        //     this.isNavigatingAway = true
        //     setTimeout(() => {
        //         this.isNavigatingAway = false
        //     }, 5000)
        // },
    },
}
</script>
<style lang="scss" scoped></style>
