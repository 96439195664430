<template>
    <div
        class="row items-center full-width"
        :class="
            isSideMenu
                ? 'justify-around q-gutter-y-md'
                : $q.screen.gt.sm
                  ? 'justify-end q-gutter-x-lg'
                  : 'justify-end q-gutter-x-sm'
        "
    >
        <!-- go to DVM -->
        <form
            v-if="curUser?.is.dvmValuer"
            ref="referenceField"
            class="col-xs-12 col-sm-auto ref-search"
            :class="{ 'apply-shake': shake }"
            @submit.prevent="() => {}"
        >
            <re-input
                v-model="dvmRef"
                :disable="isLoading"
                placeholder="Go to DVM (enter ref)"
                :size="size"
                no-bottom-slots
            >
                <template #embed>
                    <re-button
                        icon="mdi-arrow-right"
                        type="submit"
                        color="main"
                        stretch
                        :disable="!dvmRef"
                        :loading="isLoading"
                        :size="size"
                        @click="goToDvm($event)"
                    />
                </template>
            </re-input>
        </form>
        <div class="col gt-xs"></div>

        <!-- TODO: is this needed: -->
        <re-select
            v-if="curUser?.is.dvmValuer && isDispatchDashboard"
            class="col-xs-12 col-sm-auto"
            :expanded="!isCompact"
            :disable="isLoading"
        >
            <option disabled>Deployment</option>
        </re-select>

        <new-request-button
            class="col-xs-12 col-sm-auto"
            :label="isCompact ? '' : $t('dashboard.new_request.title')"
            :size="size"
        >
            <q-tooltip v-if="isCompact" class="bg-accent">
                {{ $t('dashboard.new_request.title') }}
            </q-tooltip>
        </new-request-button>

        <!-- Admin menu: -->
        <div v-if="curUser?.hasRole('admin')" class="col-auto">
            <re-button round flat icon="mdi-shield-account">
                <q-popup-proxy>
                    <admin-roles
                        :roles="roles"
                        :editable="true"
                        :unremovable="['-:admin', '*:admin']"
                        class="col-auto"
                        :size="size"
                        @select-role="curUser.patch({ roles: listToRoles($event) })"
                    />
                </q-popup-proxy>
            </re-button>
        </div>
        <!-- lang -->
        <re-multi-choice
            :model-value="$i18n.locale"
            :options="[
                { value: 'nl-BE', label: 'NL' },
                { value: 'fr-BE', label: 'FR' },
                { value: 'en-BE', label: 'EN' },
                { value: 'technical', label: 'technical', hidden: !curUser?.hasRole('developer') },
            ]"
            class="col-xs-12 col-sm-auto"
            :size="size"
            :spread="isSideMenu"
            :label="null"
            @update:model-value="changeLang"
        ></re-multi-choice>
        <!-- email -->
        <div class="gt-sm col-sm-auto text-body1 text-center" :class="`re-${size}`">
            {{ curUser?.data.email }}
        </div>

        <!-- logout -->
        <re-button
            v-if="$store.getters.isAuthenticated"
            class="col-xs-12 col-sm-auto bg-white"
            :href="logoutUrl"
            :label="isCompact ? '' : $t('monolith.global.logout')"
            :icon="isCompact ? 'mdi-logout' : undefined"
            :size="size"
            @click="() => $store.dispatch(Actions.LOGOUT)"
        >
            <q-tooltip v-if="isCompact && curUser?.data.email" class="bg-accent">
                {{ curUser?.data.email }}
            </q-tooltip>
        </re-button>
    </div>
</template>
<script setup>
import { ref, computed, inject, onMounted, nextTick } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useQuasar } from 'quasar'
import axios from '@/shared/plugins/axios'
import utils from '@/shared/plugins/utils'
import { Actions } from '@/store/Auth'

import AdminRoles from '@/shared/components/AdminRoles.vue'
import NewRequestButton from './NewRequestButton.vue'

defineProps({
    size: {
        type: String,
        default: 'sm',
    },
})

const config = inject('config')
const curUser = inject('curUser')

const router = useRouter()
const route = useRoute()
const $q = useQuasar()

const shake = ref(false)
const referenceField = ref(null)
const dvmRef = ref(null)
const isLoading = ref(false)

const isCompact = computed(() => $q.screen.sm)
const isSideMenu = computed(() => $q.screen.xs)
const logoutUrl = computed(() => utils.urlJoin(config.AUTH_API_URL, 'logout'))
const roles = computed(() => utils.rolesToList(curUser?.roles || {}))
const isDispatchDashboard = computed(() => !!config.DISPATCHED_DEPLOYMENTS?.length)

onMounted(() => {
    try {
        nextTick(() => {
            if (referenceField.value) {
                referenceField.value.addEventListener('animationend', () => {
                    shake.value = false
                })
            }
        })
    } catch (e) {
        console.log({ e })
    }
})

const changeLang = (lang) => {
    router.push({
        name: route.name,
        query: route.query,
        params: {
            ...route.params,
            lang,
        },
    })
}
const listToRoles = (roles) => {
    return utils.listToRoles(roles)
}

const goToDvm = ({ metaKey: newTab }) => {
    if (route.name === 'valuation.dvm' && dvmRef.value === route.query.valuation_request_ref && !newTab) {
        shake.value = true
        return
    }

    let name = config.ENABLE_NEW_DVM ? 'valuation.valuer-app' : 'valuation.dvm'

    isLoading.value = true
    axios
        .get(utils.urlJoin(config.VALUATION_API_URL, ['request', dvmRef.value]))
        .then(() => {
            if (newTab) {
                const routeData = router.resolve({
                    name,
                    query: {
                        valuation_request_ref: dvmRef.value,
                    },
                })
                window.open(routeData.href, '_blank')
                isLoading.value = false
                return
            }
            router.push({
                name,
                query: {
                    valuation_request_ref: dvmRef.value,
                },
            })
        })
        .catch(() => {
            shake.value = true
        })
        .finally(() => {
            isLoading.value = false
        })
}
</script>
<style lang="scss" scoped>
// no need for separate file less load with scope
@keyframes shake {
    10%,
    90% {
        transform: translate3d(-2px, 0, 0);
    }

    20%,
    80% {
        transform: translate3d(4px, 0, 0);
    }

    30%,
    50%,
    70% {
        transform: translate3d(-8px, 0, 0);
    }

    40%,
    60% {
        transform: translate3d(8px, 0, 0);
    }
}

.apply-shake {
    animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
}

.ref-search {
    transition: all 0.3s ease-in-out;
    opacity: 1;
}
</style>
